import { Actions, Mutations } from "@/store/enums/StoreEnums";
import store from "@/store/index";
import ApiService from "@/core/services/ApiService";
import { array } from "yup";
export const closeMedia = () => {
  return store.commit(Mutations.CLOSE_MEDIA);
};
export const showSingleMedia = (action) => {
  store.commit(Mutations.SELECT_SINGLE_MEDIA);
  store.commit(Mutations.SET_ACTION_CALL, action);
  return store.commit(Mutations.SHOW_MEDIA);
};
export const showMultipleMedia = (action) => {
  store.commit(Mutations.SELECT_MULTIPLE_MEDIA);
  store.commit(Mutations.SET_ACTION_CALL, action);
  return store.commit(Mutations.SHOW_MEDIA);
};
export const selectMedia = (action, data) => {
  if (store.getters["getIsMultiple"]) {
    return;
  }
  store.commit(Mutations.SET_ACTION_CALLBACK, action);
  store.commit(Mutations.SET_SELECT_MEDIA, data);
  return store.commit(Mutations.CLOSE_MEDIA);
};
export const selectMultipleMedia = (action, data) => {
  store.commit(Mutations.SET_ACTION_CALLBACK, action);
  store.commit(Mutations.SET_SELECT_MULTI_MEDIA, data);
  return store.commit(Mutations.CLOSE_MEDIA);
};
export const refreshSelectMedia = () => {
  // //console.log("1refreshSelectMedia");
  // store.commit(Mutations.SET_ACTION_CALLBACK, "");
  // store.commit(Mutations.SET_SELECT_MULTI_MEDIA, []);
  // store.commit(Mutations.SET_ACTION_CALL, "");
  // //console.log("1refreshSelectMedia");
  return store.commit(Mutations.RESET_ACTION_MEDIA);
};
export const convertParams = (params): string | null => {
  return (
    "?" +
    Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&")
  );
};
export const fileUrl = (file): string | null => {
  return process.env.VUE_APP_MEDIA_URL + file.path + "/" + file.name;
}
export const renderImageUrl = (image, size = ""): string | null => {
  let path = process.env.VUE_APP_IMG_DEFAULT_PATH
    ? process.env.VUE_APP_IMG_DEFAULT_PATH
    : "";
  let name = process.env.VUE_APP_IMG_DEFAULT_NAME
    ? process.env.VUE_APP_IMG_DEFAULT_NAME
    : "";
  if (typeof image != "undefined" && image) {
    path = image.path;
    name = image.name;
  }
  let string_size = "";
  switch (size) {
    case "medium":
      string_size = "_469_352";
      break;
    case "large":
      string_size = "_676_361";
      break;
    case "thumbnail":
      string_size = "_256_192";
      break;
    case "small":
      string_size = "_131_71";
      break;
    default:
      string_size = "";
  }
  // const encode_url =
  //   name + "?width=" + width + "&height=" + height + "&fit=cover";
  // const e64 = CryptoJS.enc.Base64.parse(
  //   CryptoJS.AES.encrypt(encode_url, "non!@#$123").toString()
  // );
  // const eHex = e64.toString(CryptoJS.enc.Hex);
  path = path.replaceAll("\\", "/");
  return (
    (process.env.VUE_APP_MEDIA_URL ? process.env.VUE_APP_MEDIA_URL : "") +
    path +
    "/" +
    name +
    string_size
  );
};
export const renderImageUrlEditor = (image): string | null => {
  let path = process.env.VUE_APP_IMG_DEFAULT_PATH
    ? process.env.VUE_APP_IMG_DEFAULT_PATH
    : "";
  let name = process.env.VUE_APP_IMG_DEFAULT_NAME
    ? process.env.VUE_APP_IMG_DEFAULT_NAME
    : "";
  if (typeof image != "undefined" && image) {
    path = image.path;
    name = image.name;
  }
  path = path.replaceAll("\\", "/");
  return (
    (process.env.VUE_APP_MEDIA_URL ? process.env.VUE_APP_MEDIA_URL : "") +
    path +
    "/" +
    name
  );
};
export const convertSlug = (str): string => {
  str = str.trim().toLowerCase();
  str = str
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
  str = str.replace(/[đĐ]/g, "d");
  str = str.replace(/([^0-9a-z-\s])/g, "");
  str = str.replace(/(\s+)/g, "-");
  str = str.replace(/-+/g, "-");
  str = str.replace(/^-+|-+$/g, "");
  return str;
};
export const getDataListObject = (
  params,
  store,
  router,
  getter_name,
  router_push
): Promise<unknown> => {
  return new Promise(function (resolve, reject) {
    store.dispatch(getter_name, convertParams(params));
    router.push({ name: router_push, query: params });
  });
};
export const getConfigs = (key): Promise<unknown> => {
  return new Promise<void>((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get("setting/pull", key)
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const setConfigs = (params): Promise<unknown> => {
  return new Promise<void>((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("setting/set", params)
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};

export const getDataProvince = (parent: string): Promise<unknown> => {
  const params = parent ? "?parent=" + parent : "";
  return new Promise<void>((resolve, reject) => {
    ApiService.setHeader();
    ApiService.setHeaderNonCache(parent);
    ApiService.getCustom("province", params)
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const UploadImage = (formData): Promise<unknown> => {
  return new Promise<void>((resolve, reject) => {
    ApiService.setHeader();
    ApiService.setFormHeader();
    ApiService.setMediaUrl();
    ApiService.post("upload/files-seo", formData)
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const getDataStreet = (province_id: string): Promise<unknown> => {
  const params = province_id ? "?province_id=" + province_id : "";
  return new Promise<void>((resolve, reject) => {
    ApiService.setHeader();
    ApiService.getCustom("street", params)
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
};
export const moneyToWord = (SoTien) => {
  let rs = "";
  if (SoTien.length > 3 && SoTien.length <= 5) {
    rs = parseInt(SoTien) / 1000 + " nghìn";
  } else if (SoTien.length > 5 && SoTien.length <= 7) {
    rs = parseInt(SoTien) / 100000 + " trăm";
  }
  if (SoTien.length > 6 && SoTien.length <= 9) {
    rs = parseInt(SoTien) / 1000000 + " triệu";
  } else if (SoTien.length >= 10) {
    rs = parseInt(SoTien) / 1000000000 + " tỷ";
  }
  return rs;
};
export const formatCurrency = (value: string) => {
  value = value.replaceAll(".", "");
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
// export const endCodeData = (data): string => {
//   return crypto.createHash('md5').update(data).digest('hex')
// };
export const removeComma = (str) => {
  str = String(str);
  return Number(str.replaceAll(",", "."));
};
export const findItemArrayChildren = (dataArr: any[] = [], id) => {
  for (const item of dataArr) {
    const result =
      item._id === id ? item : findItemArrayChildren(item.children, id);
    if (result) return result;
  }
  return null;
};
export default {
  findItemArrayChildren,
  getDataProvince,
  removeComma,
  formatCurrency,
  getDataStreet,
  moneyToWord,
  convertParams,
  getConfigs,
  getDataListObject,
  convertSlug,
  fileUrl,
  UploadImage,
  setConfigs,
  renderImageUrlEditor,
  renderImageUrl,
};
